import React from 'react';

import withBaseHead from '@zaritalk/lessor/hoc/withBaseHead';
import { Layout, LegacyTypography, ImageMemo, Button } from '@zaritalk/ui-components/index';

export default withBaseHead(function Custom404() {
  return (
    <Layout.ColCenter
      css={{
        maxWidth: '300px',
        margin: '24px auto',
        safeAreaPaddingTop: '0px',
        safeAreaPaddingBottom: '0px',
      }}
    >
      <section>
        <ImageMemo
          width={'100%'}
          height={'100%'}
          src={'https://static.zaritalk.com/images/img-talklogo-148-42%402x.png'}
          alt={'자리톡 로고'}
        />
      </section>

      <ImageMemo
        src={'https://static.zaritalk.com/images/img-under-maintenance%402x.png'}
        alt={'자리톡 없는 페이지'}
        width={'100%'}
        style={{ margin: '64px 0 23px' }}
      />

      <LegacyTypography is={'h2'} css={{ marginBottom: '12px' }}>
        {'알 수 없는 페이지입니다.'}
      </LegacyTypography>
      <Button fullWidth={true} onClick={() => window.location.replace('/')}>
        {'돌아가기'}
      </Button>
    </Layout.ColCenter>
  );
});
