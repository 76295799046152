import { ButtonHTMLAttributes, cloneElement, forwardRef, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';

import { COLOR } from '@zaritalk/constants';
import { styled } from '@zaritalk/panda-css/jsx';
import { StyledVariantProps, SystemStyleObject } from '@zaritalk/panda-css/types';

import DotFlashing from '../DotFlashing';

export type ButtonVariants = StyledVariantProps<typeof Button>;
export type ButtonProps = {
  css?: SystemStyleObject | SystemStyleObject[];
  children: ReactNode;
  leftSideIcon?: JSX.Element;
  rightSideIcon?: JSX.Element;
  useAnimation?: boolean;
};

const FILL_TYPE = {
  PRIMARY: COLOR.WHITE,
  SECONDARY: COLOR.GRAY05,
  SECONDARY_PRESS: COLOR.PRIMARY01,
  SECONDARY_VARIATION: COLOR.PRIMARY01,
  SECONDARY_FILTER: COLOR.WHITE,
  WARNING: COLOR.RED,
} as const;

export default forwardRef<HTMLButtonElement, ButtonVariants & ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  function BasicButton(
    {
      css: buttonCss = {},
      size,
      isLoading = false,
      disabled,
      variant = 'PRIMARY',
      position = 'DEFAULT',
      children,
      leftSideIcon,
      rightSideIcon,
      onClick = () => ({}),
      useAnimation = true,
      ...restProps
    },
    ref,
  ) {
    const { loadingShow, contentsShow } = useSpring({
      from: { loadingShow: 0.8, contentsShow: 1 },
      loadingShow: isLoading ? 1 : 0.8,
      contentsShow: isLoading ? 0.9 : 1,
      config: { duration: 100 },
    });
    const isVisibleDotFlashing = isLoading && !disabled;

    return (
      <Button
        ref={ref}
        size={size}
        position={position}
        variant={variant}
        isLoading={isLoading}
        onClick={onClick}
        disabled={disabled}
        css={{ transform: useAnimation ? 'scale(1)' : 'none', ...buttonCss }}
        {...restProps}
      >
        <animated.div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            transform: useAnimation
              ? isVisibleDotFlashing
                ? loadingShow.to((value) => `scale(${value})`)
                : contentsShow.to((value) => `scale(${value})`)
              : 'none',
          }}
        >
          <ButtonContentWrapper isVisible={!isVisibleDotFlashing}>
            {leftSideIcon &&
              cloneElement(leftSideIcon, {
                ...leftSideIcon.props,
                fill: disabled ? COLOR.GRAY04 : FILL_TYPE[variant as keyof typeof FILL_TYPE],
              })}
            {children}
            {rightSideIcon &&
              cloneElement(rightSideIcon, {
                ...rightSideIcon.props,
                fill: disabled ? COLOR.GRAY04 : FILL_TYPE[variant as keyof typeof FILL_TYPE],
              })}
          </ButtonContentWrapper>
          {isVisibleDotFlashing && <DotFlashing css={{ position: 'absolute' }} type={variant} />}
        </animated.div>
      </Button>
    );
  },
);

const Button = styled(
  'button',
  {
    base: {
      margin: '0',
      padding: '12px 0',

      whiteSpace: 'nowrap',
      typography: '16_BOLD_WIDE',
      textAlign: 'center',

      border: 'none',
      outline: 'none',
      userSelect: 'none',
      cursor: 'pointer',

      transition: 'all .2s',
      opacity: 1,

      '&:active': {
        transform: 'scale(0.98)',
        opacity: 0.8,
      },
    },

    variants: {
      size: {
        normal: {
          fontSize: '16px',
        },
        small: {
          fontSize: '14px',
        },
        smaller: {
          fontSize: '12px',
        },
      },
      variant: {
        PRIMARY: {
          backgroundColor: '$primary01',
          color: '$white',
        },
        PRIMARY_BLACK: {
          backgroundColor: '$black',
          color: '$white',
        },
        PRIMARY_WHITE: {
          backgroundColor: '$white',
          color: '$black',
        },
        SECONDARY: {
          padding: '11px 0',
          border: 'solid 1px token(colors.$gray03)',
          backgroundColor: '$white',
          color: '$gray05',
        },
        SECONDARY_PRESS: {
          padding: '11px 0',
          border: 'solid 1px token(colors.$primary01)',
          backgroundColor: '$white',
          color: '$primary01',
        },
        SECONDARY_VARIATION: {
          backgroundColor: 'token(colors.$primary02)',
          color: '$primary01',
        },
        SECONDARY_FILTER: {
          padding: '11px 0',
          backgroundColor: '$white',
          border: 'solid 1px token(colors.$white)',
          color: '$gray06',
        },
        WARNING: {
          backgroundColor: '$white',
          border: 'solid 1px token(colors.$red)',
          color: '$red',
        },
      },
      disabled: {
        true: {
          backgroundColor: '$gray02',
          color: '$gray04',
          pointerEvents: 'none',
          cursor: 'not-allowed',
        },
      },
      brightDisabled: {
        true: {
          backgroundColor: '$white',
          border: 'solid 1px token(colors.$gray02)',
          color: '$gray04',
          pointerEvents: 'none',
          cursor: 'not-allowed',
        },
      },
      position: {
        TOP: {
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
        },
        BOTTOM: {
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
        LEFT: {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
        RIGHT: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        },
        CENTER: {
          borderRadius: 0,
        },
        DEFAULT: {
          borderRadius: '8px',
        },
      },
      fullWidth: {
        true: {
          width: '100%',
        },
      },
      isLoading: {
        true: {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        },
      },
    },
  },
  {
    defaultProps: {
      size: 'normal',
      variant: 'PRIMARY',
      position: 'DEFAULT',
      disabled: false,
      isLoading: false,
    },
  },
);

const ButtonContentWrapper = styled('div', {
  base: {
    flexRow: 'CENTER',
    gap: '4px',
  },

  variants: {
    isVisible: {
      false: {
        visibility: 'hidden',
      },
    },
  },
});
