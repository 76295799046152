import { styled } from '@zaritalk/panda-css/jsx';

const DotFlashing = styled(
  'div',
  {
    base: {
      position: 'relative',

      width: '4px',
      height: '4px',
      borderRadius: '5px',

      _before: {
        left: '-8px',
        width: '4px',
        height: '4px',
        borderRadius: '5px',

        content: '""',
        display: 'inline-block',
        position: 'absolute',
        top: 0,
      },

      _after: {
        left: '8px',
        width: '4px',
        height: '4px',
        borderRadius: '5px',

        content: '""',
        display: 'inline-block',
        position: 'absolute',
        top: 0,
      },
    },

    variants: {
      type: {
        PRIMARY: {
          backgroundColor: '#2c7fff',
          color: '#2c7fff',
          animation: 'primaryDotFlashing .5s .25s infinite linear alternate',

          _before: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s 0s infinite alternate',
          },

          _after: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s .5s infinite alternate',
          },
        },
        PRIMARY_BLACK: {
          backgroundColor: '#222222',
          color: '#222222',
          animation: 'primaryBlackDotFlashing .5s .25s infinite linear alternate',

          '&::before': {
            backgroundColor: '#222222',
            color: '#222222',
            animation: 'primaryBlackDotFlashing .5s 0s infinite alternate',
          },

          '&::after': {
            backgroundColor: '#222222',
            color: '#222222',
            animation: 'primaryBlackDotFlashing .5s .5s infinite alternate',
          },
        },
        PRIMARY_WHITE: {
          backgroundColor: '#ffffff',
          color: '#ffffff',
          animation: 'primaryWhiteDotFlashing .5s .25s infinite linear alternate',

          '&::before': {
            backgroundColor: '#ffffff',
            color: '#ffffff',
            animation: 'primaryWhiteDotFlashing .5s 0s infinite alternate',
          },

          '&::after': {
            backgroundColor: '#ffffff',
            color: '#ffffff',
            animation: 'primaryWhiteDotFlashing .5s .5s infinite alternate',
          },
        },
        SECONDARY: {
          backgroundColor: '$gray05',
          color: '#7a7a7a',
          animation: 'secondaryDotFlashing .5s .25s infinite linear alternate',

          _before: {
            backgroundColor: '#7a7a7a',
            color: '#7a7a7a',
            animation: 'secondaryDotFlashing .5s 0s infinite alternate',
          },

          _after: {
            backgroundColor: '#7a7a7a',
            color: '#7a7a7a',
            animation: 'secondaryDotFlashing .5s .5s infinite alternate',
          },
        },
        SECONDARY_PRESS: {
          backgroundColor: '#2c7fff',
          color: '#2c7fff',
          animation: 'secondaryDotFlashing .5s .25s infinite linear alternate',

          _before: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s 0s infinite alternate',
          },

          _after: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s .5s infinite alternate',
          },
        },
        SECONDARY_VARIATION: {
          backgroundColor: '#2c7fff',
          color: '#2c7fff',
          animation: 'secondaryDotFlashing .5s .25s infinite linear alternate',

          _before: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s 0s infinite alternate',
          },

          _after: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s .5s infinite alternate',
          },
        },
        SECONDARY_FILTER: {
          backgroundColor: '#2c7fff',
          color: '#2c7fff',
          animation: 'secondaryDotFlashing .5s .25s infinite linear alternate',

          _before: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s 0s infinite alternate',
          },

          _after: {
            backgroundColor: '#2c7fff',
            color: '#2c7fff',
            animation: 'primaryDotFlashing .5s .5s infinite alternate',
          },
        },
        WARNING: {
          backgroundColor: '#e74d5a',
          color: '#e74d5a',
          animation: 'warningDotFlashing .5s .25s infinite linear alternate',

          _before: {
            backgroundColor: '#e74d5a',
            color: '#e74d5a',
            animation: 'warningDotFlashing .5s 0s infinite alternate',
          },

          _after: {
            backgroundColor: '#e74d5a',
            color: '#e74d5a',
            animation: 'warningDotFlashing .5s .5s infinite alternate',
          },
        },
      },
    },
  },
  {
    defaultProps: {
      type: 'PRIMARY',
    },
  },
);

export default DotFlashing;
